import { Group } from '@mv-submodules/inplant-asset-manager-fe/types/groups'

export interface AssetsListElement {
  id: string
  assetCode: string
  positionCode: string
  status: AssetStatus,
  assetDescription: string,
  activatedOn: string,
  deactivedOn: string | null
  plant: Plant,
  costCenter: CostCenter,
  groups: AssetGroup[]
}

export interface AssetDetail {
  id: string
  assetId: string
  assetCode: string
  positionCode: string
  plantCode: string
  status: AssetStatus
  assetDescription: string
  activatedOn: string
  deactivatedOn:  string | null
  site: Site
  plant: Plant,
  costCenter: CostCenter,
  positionData: {
    [k: string]: any
  },
  assetData: AssetData
  attachments: Attachments[]
  actionLogs: ActionLog[],
  modules: Module[]
  groups: Group[],
  assetConfigurationTasksStatus?: AssetConfigurationStatusTask
  isReplacing: AssetOnReplacing | null
  isBeingReplacedBy: AssetOnReplacing | null
  temporaryPlantCode?: boolean
}

export interface AssetOnReplacing {
  id: string
  assetId: string
  assetCode: string
  positionCode: string
  status: string
}

export interface AssetsListFilters {
  searchString: string | null
  plant: string | null
  costCenter: string | null
  status: AssetStatus | null
}

export interface ListPagination {
  pageSize: number
  pageNumber: number
  sortField?: string
  sortDirection?: string
}

export type AssetStatus = 'active' | 'creation-to-validate' | 'deactivation-to-validate' | 'deactivated' | 'scrapping-to-validate' | 'replace-with-scrapping-to-validate' | 'replace-with-deactivation-to-validate' | 'replace-to-validate' | 'documents-to-validate' | 'freezing-to-validate' | 'selling-to-validate'

export type AssetFormStatus = 'create' | 'validate' | 'update' | 'substitution-new-asset' | 'substitution-with-deactivated' | 'validate-replacing-with-deactivated-asset' | 'validate-replacing-with-new-asset' | 'documents-to-validate'

export interface Site {
  name: string
  code: string
  plants: Plant[]
}

export interface Module {
  id: string
  name: string
  description: string | null
}

export interface Plant {
  code: string
  name: string,
  costCenters: CostCenter[]
}

export interface CostCenter {
  code: string,
  name: string
}

export interface AssetGroup {
  id: string,
  name: string
}

interface AssetData {
  brand: string,
  model: string,
  serialNumber: string,
  ownership: string,
  notes: string,
  documents: AssetFile[]
  attributes: {
    [k: string]: any
  }
}

export interface Attachments {
  id: string
  link: string
  name: string
  description: string
  type: string
  fileType: string
}

interface ActionLog {
  id: string
  type: string
  date: string
  data: {text?: string}
  user: string
}

// Asset Creation

export interface AssetForm {
  assetCode: string
  activationDate:  string
  positionCode: string
  assetDescription: string
  assetNotes: string
  siteCode: string
  plantCode: string
  costCenterCode: string
  assetBrand: string
  assetModel: string
  assetSerialNumber: string
  ownership: string
  groups: string[]
  modules: string[]
  documents: AssetFileUpload[],
  temporaryPlantCode?: boolean
}

export interface AssetFile {
  date: string
  fileId: string
  id: string
  name: string
  type: string
  mimeType: string
  description: string
  isInHeadquarter: boolean
  url: string
  editable: boolean
}

export interface AssetFileUpload {
  id?:string
  fileId: string
  name: string
  type: string
  isInHeadquarter: boolean
  description: string
}

export interface AssetFileCreation {
  file?: File
  description?: string
  type?: string | 'ce'
  isInHeadquarter?: boolean | false
}

// Asset Validation

export interface AssetValidation {
  assetCode: string
  assetDescription: string
  assetNotes: string
  assetBrand: string
  assetModel: string
  assetSerialNumber: string
  ownership: string
  groups: string[]
  modules: string[]
  documents: AssetFileUpload[]
  positionCode?: string
}

export interface AssetDocumentsValidationPayload {
  assetDescription: string
  assetNotes: string
  assetBrand: string
  assetModel: string
  assetSerialNumber: string
  groups: string[]
  modules: string[]
  documents: AssetFileUpload[]
}

// assetUpdate
export interface AssetUpdate {
  assetDescription: string
  assetNotes: string
  assetBrand: string
  assetModel: string
  assetSerialNumber: string
  ownership: string
  groups: string[]
  modules: string[]
  documents: AssetFileUpload[]
}

export interface AssetModulesStatus {
  'chrono-frame': {name: string, values: AssetTask[]}
  titech?: {name: string, values: any}
  plc?: {name: string, values: any}
  'plant-analysis'?: {name: string, values: any}
  halts?: {name: string, values: any}
  checklist?: {name: string, values: any}
}

export interface AssetTask {
  id: string
  taskId: string
  date: string
  status: string
  ambit: string | null
  category: string | null
  subCategory: string | null
  activity: string
  description: string
  chronoFrameLink: string
}

export interface AssetTaskForCopy {
  id: string
  taskId: string
  date: string
  status: string
  ambit: string | null
  category: string | null
  subCategory: string | null
  activity: string
  description: string
  legislativeReference: string
  chronoFrameLink: string
  recurrent: boolean
}

export interface AssetConfigurationStatusTask {
  done: number
  toDo: number
}

export interface CopyTasksBody {
  sourceAssetId: string,
  tasks: AssetsBatchTask[]
}

export interface AssetsBatchTask {
  taskId: string
  creationDate: string
  dueAtDate?: string
}

export interface RequestDisableAssetForm {
  date: string
  action: 'scrap' | 'sell' | 'freeze'
  note: string
}

export interface AssetSubsititutionForm {
  replacedDate: string
  replacedAction: string
  replacedNote?: string
  activatedNote?: string
  assetId?: string
  assetData?: AssetForm
}

export interface AssetValidateActionAvailabilty {
  message: {
    mainText: string,
    subElements: Array<{link?: string, text: string}>
  }
  allowed: boolean
  showMessage: boolean
}

export interface DeactivatedAsste {
  id: string
  code: string
  description: string
  brand: string
  model: string
  serialNumber: string
  costCenter: {
    code: string
    name: string
  }
  plant: {
    code: string
    name: string
  }
}


export enum AssetManagerAcl {
  create = 'asset-manager.create',
  validateCreation = 'asset-manager.validate-creation',
  edit = 'asset-manager.edit',
  deactivated = 'asset-manager.deactivate',
  validateDeactivation = 'asset-manager.validate-deactivation',
  replace = 'asset-manager.replace',
  validateReplacing = 'asset-manager.validate-replacing',
  validateDocuments = 'asset-manager.validate-documents',
  updateMachineCode = 'asset-manager.update-asset-code',
  addAssetNote = 'asset-manager.add-asset-note',
  copyAssetTasks = 'asset-manager.copy-asset-tasks',
  createGroup = 'asset-manager.create-group',
  updateGroup = 'asset-manager.update-group'
}