import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import {
  AssetForm as AssetFormType,
  AssetFileCreation,
  CostCenter,
  Module,
  Site,
  AssetFileUpload,
  AssetFormStatus,
} from '@mv-submodules/inplant-asset-manager-fe/types/asset'
import { GroupsListElement } from '@mv-submodules/inplant-asset-manager-fe/types/groups'
import { Button, Checkbox, DateInput, Input, Select, TextArea } from '@mv-submodules/inplant-components-fe'
import FileUpload from '@mv-submodules/inplant-asset-manager-fe/ui/components/widgets/File/FileUpload'
import FileUpdate from '@mv-submodules/inplant-asset-manager-fe/ui/components/widgets/File/FileUpdate'
import { SelectOptions } from '@mv-submodules/inplant-components-fe/ui/components/Input/Select'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'

interface StateProps {
  assetData: AssetFormType
  plants: Array<{ code: string; name: string }>
  sites: Site[]
  costCenters: Array<{ plant: string; costCenters: CostCenter[] }>
  groups: GroupsListElement[]
  modules: Module[]
  documents: AssetFileCreation[]
  updateAssetData: (assetData: AssetFormType) => void
  type: AssetFormStatus
  getInputRule: (name: string, action: string) => any
  editableDocuments?: string[]
}

interface OwnState {
  plants: Array<{ code: string; name: string }>
  sites: Site[]
  costCenters: Array<{ plant: string; costCenters: CostCenter[] }>
  groups: GroupsListElement[]
  modules: Module[]
  documents: AssetFileCreation[]
  currentGroup: string
  alreadyCheckedModules: string[]
}

type Props = StateProps & WithTranslation

class AssetForm extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      plants: this.props.plants,
      sites: this.props.sites,
      documents: this.props.documents,
      modules: this.props.modules,
      groups: this.props.groups,
      costCenters: this.props.costCenters,
      currentGroup: '',
      alreadyCheckedModules: JSON.parse(JSON.stringify(this.props.assetData.modules)),
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleChangePlantCode = this.handleChangePlantCode.bind(this)
    this.getAvailableCostCenter = this.getAvailableCostCenter.bind(this)
    this.handleAddAssetGroup = this.handleAddAssetGroup.bind(this)
    this.handleChangeAssetGroup = this.handleChangeAssetGroup.bind(this)
    this.handleRemoveAssetGroup = this.handleRemoveAssetGroup.bind(this)
    this.handleAddDocument = this.handleAddDocument.bind(this)
    this.handleRemoveDocument = this.handleRemoveDocument.bind(this)
    this.updateDocument = this.updateDocument.bind(this)
    this.handleChangeModule = this.handleChangeModule.bind(this)
    //  this.getInputRule = this.getInputRule.bind(this)
    this.updateDocumentAlreadyUploaded = this.updateDocumentAlreadyUploaded.bind(this)
    this.removeDocumentAlreadyUploaded = this.removeDocumentAlreadyUploaded.bind(this)
    this.getSelectStaticOption = this.getSelectStaticOption.bind(this)
  }

  public componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any) {
    this.setState({
      plants: this.props.plants,
      sites: this.props.sites,
      documents: this.props.documents,
      modules: this.props.modules,
      groups: this.props.groups,
      costCenters: this.props.costCenters,
    })
  }

  public render() {
    const { t, assetData, updateAssetData, getInputRule } = this.props
    const { plants, sites, costCenters, groups, modules, documents, currentGroup, alreadyCheckedModules } = this.state
    const {
      handleInputChange,
      handleChangePlantCode,
      handleAddAssetGroup,
      handleChangeAssetGroup,
      handleRemoveAssetGroup,
      handleAddDocument,
      handleRemoveDocument,
      updateDocument,
      handleChangeModule,
      getAvailableCostCenter,
    } = this

    const modulesDisabled = getInputRule('modules', 'disabled')
    const modulesAllowOnlyNews = getInputRule('modules', 'allow-only-news')

    return (
      <>
        {/* Activation Date*/}
        <Row verticalAlignment={'center'}>
          <Column md={4}>
            <DateInput
              id="form-activationDate"
              label={t('assetManager.forms.activationDate')}
              onChange={value => this.handleInputChange(undefined, { name: 'activationDate', value })}
              value={assetData.activationDate}
              name="activationDate"
              disabled={getInputRule('activationDate', 'disabled')}
              readOnly={getInputRule('activationDate', 'readOnly')}
              min={getInputRule('activationDate', 'min')}
            />
          </Column>
          {getInputRule('temporaryPlantCode', 'visible') && (
            <Column md={4}>
              <div className="form-check">
                <Checkbox
                  checked={assetData.temporaryPlantCode || false}
                  name="temporaryPlantCode"
                  id="asset-temporary-plantcode"
                  onChange={value =>
                    this.handleInputChange(undefined, {
                      name: 'temporaryPlantCode',
                      value: value.checked,
                    })
                  }
                  label="Codice impianto temporaneo"
                />
              </div>
            </Column>
          )}
        </Row>
        {/* End Activation Date*/}
        {/* MachineCode - PlantCode - AssetDescription*/}
        <Row>
          <Column md={4}>
            <Input
              id="form-machineCode"
              label={t('assetManager.forms.machineCode')}
              name="assetCode"
              onChange={value => this.handleInputChange(undefined, { name: 'assetCode', value: value as string })}
              value={assetData.assetCode}
              disabled={getInputRule('assetCode', 'disabled')}
              readOnly={getInputRule('assetCode', 'readOnly')}
              required={getInputRule('assetCode', 'required')}
            />
          </Column>
          <Column md={4}>
            <Input
              id="form-plantCode"
              label={t('assetManager.forms.plantCode')}
              name="positionCode"
              onChange={value => this.handleInputChange(undefined, { name: 'positionCode', value: value as string })}
              value={assetData.positionCode}
              disabled={getInputRule('positionCode', 'disabled')}
              readOnly={getInputRule('positionCode', 'readOnly')}
              required={true}
            />
          </Column>
          <Column md={4}>
            <Input
              id="form-assetDescription"
              label={t('assetManager.forms.assetDescription')}
              name="assetDescription"
              onChange={value =>
                this.handleInputChange(undefined, {
                  name: 'assetDescription',
                  value: value as string,
                })
              }
              value={assetData.assetDescription}
              required={true}
            />
          </Column>
        </Row>
        {/* End MachineCode - PlantCode - AssetDescription*/}
        <hr />
        {/* Plant - Site - CostCenter*/}
        <Row>
          <Column md={4}>
            <Select
              value={assetData.plantCode}
              disabled={getInputRule('plantCode', 'disabled')}
              name="plantCode"
              required={true}
              id="form-plant"
              label={t('assetManager.forms.plant')}
              onChange={value => handleChangePlantCode(undefined, value)}
              options={this.getSelectOptions(plants, '--')}
            />
          </Column>
          <Column md={4}>
            <Select
              value={assetData.siteCode}
              disabled={getInputRule('siteCode', 'disabled')}
              label={t('assetManager.forms.site')}
              name="siteCode"
              required={true}
              id="form-site"
              onChange={value => handleChangePlantCode(undefined, value)}
              options={this.getSelectOptions(sites, '--')}
            />
          </Column>
          <Column md={4}>
            <Select
              value={assetData.costCenterCode}
              disabled={costCenters.length === 0 || getInputRule('costCenterCode', 'disabled')}
              label={t('assetManager.forms.costCenter')}
              name="costCenterCode"
              required={true}
              id="form-costCenter"
              onChange={value => handleChangePlantCode(undefined, assetData.plantCode, value)}
              options={this.getSelectOptions(getAvailableCostCenter(assetData.plantCode))}
            />
          </Column>
        </Row>
        {/* End Plant - Site - CostCenter*/}
        <hr />
        {/* Brand - Model - SerialNumber*/}
        <Row>
          <Column md={4}>
            <Input
              id="form-brand"
              label={t('assetManager.forms.brand')}
              name="assetBrand"
              onChange={value => this.handleInputChange(undefined, { name: 'assetBrand', value: value as string })}
              value={assetData.assetBrand}
            />
          </Column>
          <Column md={4}>
            <Input
              id="form-model"
              label={t('assetManager.forms.model')}
              name="assetModel"
              onChange={value => this.handleInputChange(undefined, { name: 'assetModel', value: value as string })}
              value={assetData.assetModel}
            />
          </Column>
          <Column md={4}>
            <Input
              id="form-serialNumber"
              label={t('assetManager.forms.serialNumber')}
              name="assetSerialNumber"
              onChange={value =>
                this.handleInputChange(undefined, {
                  name: 'assetSerialNumber',
                  value: value as string,
                })
              }
              value={assetData.assetSerialNumber}
            />
          </Column>
          <Column md={4}>
            <Select
              id="form-priority"
              required={false}
              label={t('assetManager.forms.owner')}
              name="assetPriority"
              onChange={value => this.handleChangeOwner(value)}
              value={assetData.ownership}
              options={this.getSelectStaticOption()}
            />
          </Column>
        </Row>
        {/* End Brand -Model - SerialNumber*/}
        {/* AssetNotes*/}
        <Row>
          <Column md={12}>
            <TextArea
              id="form-asset-group"
              value={assetData.assetNotes}
              onChange={value => handleInputChange(undefined, { name: 'assetNotes', value: value as string })}
              name="assetNotes"
              label={t('assetManager.forms.notes')}
            />
          </Column>
        </Row>
        {/* End AssetNotes*/}
        {!getInputRule('groups', 'hide') && (
          <>
            <hr />
            {/* Groups Section */}
            <label className="">
              <strong>{t('assetManager.forms.assetsGroups')}</strong>
            </label>
            <div>
              {assetData.groups.map((group, index) => (
                <Row key={`label_group_${group}`} verticalAlignment={'center'}>
                  <Column md={6}>
                    <p className="mb-0 mt-0">
                      <strong>{groups.find(value => value.id === group)!.name}</strong>
                    </p>
                  </Column>
                  <Column xs={1}>
                    <Button
                      variant="danger-alternate"
                      type="button"
                      onClick={() => handleRemoveAssetGroup(index)}
                      icon={'trash'}
                    />
                  </Column>
                </Row>
              ))}
              {groups && groups.filter(group => !assetData.groups.includes(group.id)).length > 0 && (
                <Row verticalAlignment={'center'}>
                  <Column md={6}>
                    <Select
                      value={currentGroup}
                      label={t('assetManager.forms.selectGroup')}
                      name="costCenterCode"
                      onChange={value => handleChangeAssetGroup(undefined, value)}
                      options={this.getSelectOptions(groups, t('assetManager.forms.selectGroup'))}
                    />
                  </Column>
                  <Column xs={1}>
                    <Button
                      variant="primary-alternate"
                      onClick={handleAddAssetGroup}
                      disabled={currentGroup === ''}
                      icon={'plus'}
                    />
                  </Column>
                </Row>
              )}
            </div>
            {/* End Groups Section*/}
          </>
        )}
        {!getInputRule('documents', 'hide') && (
          <>
            <hr />
            {/* Documents Section*/}
            {(this.props.type === 'validate' ||
              this.props.type === 'update' ||
              this.props.type === 'documents-to-validate' ||
              this.props.type === 'create') &&
              assetData.documents.map((document, index) => (
                <FileUpdate
                  file={document}
                  index={index}
                  editable={
                    this.props.editableDocuments === undefined || this.props.editableDocuments.includes(document.fileId)
                  }
                  removeDocument={this.removeDocumentAlreadyUploaded}
                  updateFile={this.updateDocumentAlreadyUploaded}
                />
              ))}
            {documents.map((document, index: number) => (
              <FileUpload
                file={document}
                index={index}
                updateFile={updateDocument}
                removeDocument={handleRemoveDocument}
                key={`document_upload_${index}`}
              />
            ))}
            {!getInputRule('add-document-button', 'hide') && (
              <div className={'mx-2'}>
                <Button
                  variant={'primary-alternate'}
                  onClick={handleAddDocument}
                  label={this.props.t('assetManager.actions.addDocument')}
                />
              </div>
            )}
            {/* End Documents Section*/}
          </>
        )}
        {!getInputRule('modules', 'hide') && (
          <>
            <hr />
            {/* Modules Section*/}
            <label className="">
              <strong>{t('assetManager.forms.modules')}</strong>
            </label>
            <Row spacing={{ horizontal: false, vertical: false }}>
              {modules &&
                modules.map(module => (
                  <Column md={3} key={`modules_checkbox_${module.id}`}>
                    <div className="form-check">
                      <Checkbox
                        id={`modules_checkbox_${module.id}`}
                        checked={assetData.modules.includes(module.id) || module.id === 'chrono-frame'}
                        value={module.id}
                        disabled={
                          modulesDisabled ||
                          module.id === 'chrono-frame' ||
                          (modulesAllowOnlyNews && alreadyCheckedModules && alreadyCheckedModules.includes(module.id))
                        }
                        onChange={() =>
                          handleChangeModule(undefined, {
                            value: module.id as string,
                            assetData,
                            updateData: updateAssetData,
                          })
                        }
                        label={module.name}
                      />
                    </div>
                  </Column>
                ))}
            </Row>
          </>
        )}
        {/* End Modules Section*/}
      </>
    )
  }

  private handleChangePlantCode(
    event?: React.ChangeEvent<HTMLSelectElement>,
    plantCodeParm?: string,
    costCenterCode?: string
  ) {
    const plantCode = (event && event.target.value) || (plantCodeParm && plantCodeParm) || ''
    const findedSite =
      this.props.sites && this.props.sites.find(site => site.plants.find(plant => plant.code === plantCode))
    const costCenters = this.getAvailableCostCenter(plantCode)
    this.props.updateAssetData({
      ...this.props.assetData,
      plantCode,
      siteCode: findedSite && findedSite?.code ? findedSite?.code : '',
      costCenterCode:
        costCenterCode && costCenters.find(c => c.code === costCenterCode) ? costCenterCode : costCenters?.[0]?.code,
    })
  }

  private getAvailableCostCenter(plantCode: string) {
    const findedPlant = this.props.costCenters.find(plant => plant.plant === plantCode)
    return (findedPlant && findedPlant.costCenters) || []
  }

  private handleInputChange(
    event?: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    state?: { name: string; value: string | boolean }
  ) {
    const { assetData } = this.props
    if (event || state) {
      const { name, value } = (event && event.target) || state!
      switch (name) {
        case 'temporaryPlantCode':
          if (event) {
            assetData[name] = !this.props.assetData.temporaryPlantCode
            break
          }
          if (state) {
            assetData[name] = state.value as boolean
            break
          }
          break

        default:
          assetData[name] = value
          break
      }
      this.props.updateAssetData(assetData)
    }
  }

  private handleAddAssetGroup() {
    const groups = this.props.assetData.groups
    const { currentGroup } = this.state
    groups.push(currentGroup)
    this.props.updateAssetData({
      ...this.props.assetData,
      groups,
    })
    this.setState({
      currentGroup: '',
    })
  }

  private handleChangeAssetGroup(event?: React.ChangeEvent<HTMLSelectElement>, value?: string) {
    const currentGroup = (event && event.target.value) || value || ''
    this.setState({ currentGroup })
  }

  private handleRemoveAssetGroup(index: number) {
    const groups = this.props.assetData.groups
    groups.splice(index, 1)
    this.props.updateAssetData({
      ...this.props.assetData,
      groups,
    })
  }

  private handleAddDocument() {
    const { documents } = this.state
    documents.push({
      type: 'ce',
    })
    this.setState({ documents })
  }

  private handleRemoveDocument(index: number) {
    const { documents } = this.state
    documents.splice(index, 1)
    this.setState({ documents })
  }

  private updateDocument(file: AssetFileCreation, index: number) {
    const { documents } = this.state
    documents[index] = file
    this.setState({ documents })
  }

  private handleChangeModule(
    event?: React.ChangeEvent<HTMLInputElement>,
    options?: { value: string; assetData: AssetFormType; updateData: (assetData: AssetFormType) => void }
  ) {
    const modules = (options && options.assetData.modules) || this.props.assetData.modules
    const module = (event && event.target.value) || (options && options.value) || ''
    const moduleIndex = modules.indexOf(module)
    if (moduleIndex === -1) {
      modules.push(module)
    } else {
      modules.splice(moduleIndex, 1)
    }

    if (event) {
      this.props.updateAssetData({
        ...this.props.assetData,
        modules,
      })
    } else if (options) {
      options.updateData({
        ...options.assetData,
        modules,
      })
    }
  }

  private updateDocumentAlreadyUploaded(file: AssetFileUpload, index: number) {
    const { documents } = this.props.assetData
    documents[index] = file
    this.props.updateAssetData({
      ...this.props.assetData,
      documents,
    })
  }

  private removeDocumentAlreadyUploaded(index: number) {
    const { documents } = this.props.assetData
    documents.splice(index, 1)
    this.props.updateAssetData({
      ...this.props.assetData,
      documents,
    })
  }

  private getSelectOptions(
    sources: Array<{ name: string; code?: string; id?: string }>,
    defaultOption?: string
  ): SelectOptions {
    const options: SelectOptions = {
      defaultOption: defaultOption || undefined,
      items: [],
    }
    sources.map(source => {
      if (source.code || source.id) {
        options.items.push({
          label: source.name,
          value: (source.code || source.id)!,
        })
      }
    })

    return options
  }

  private getSelectStaticOption(): SelectOptions {
    return {
      items: [
        {
          value: '',
          label: '-',
        },
        {
          value: 'owned',
          label: this.props.t('assetManager.forms.ownerType.owned'),
        },
        {
          value: 'rented',
          label: this.props.t('assetManager.forms.ownerType.rented'),
        },
        {
          value: 'leased',
          label: this.props.t('assetManager.forms.ownerType.leased'),
        },
        {
          value: 'subSupply',
          label: this.props.t('assetManager.forms.ownerType.subSupply'),
        },
      ],
    }
  }

  private handleChangeOwner(assetPriority: string) {
    console.log(assetPriority) //tslint:disable-line
    this.props.updateAssetData({
      ...this.props.assetData,
      ownership: assetPriority,
    })
  }
}

export default withTranslation()(AssetForm)
