import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Button, Loader, PageHeader } from '@mv-submodules/inplant-components-fe'
import {
  AssetForm as AssetFormType,
  CostCenter,
  Site,
  Module,
  AssetFileCreation,
  AssetFileUpload,
  AssetFile,
} from '@mv-submodules/inplant-asset-manager-fe/types/asset'
import moment from 'moment'
import { FetchSuccessCreateAsset, FetchUploadDocumentSuccess } from '../../../../types/fetchData'
import {
  fetchDeactivatedAssetsList,
  fetchSitesGroupsModules,
} from '@mv-submodules/inplant-asset-manager-fe/redux/actions/assetsList'
import {
  fetchCreateAsset,
  fetchUploadFIle,
  getAssetFormRules,
} from '@mv-submodules/inplant-asset-manager-fe/redux/actions/asset'
import { GroupsListElement } from '@mv-submodules/inplant-asset-manager-fe/types/groups'
import AssetForm from '@mv-submodules/inplant-asset-manager-fe/ui/components/widgets/AssetForm/AssetForm'
import SearchableSelect from '@mv-submodules/inplant-components-fe/ui/components/Input/SearchableSelect'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'

interface DeactivatedAsset {
  brand: string | null
  code: string
  description: string
  id: string
  model: string | null
  serialNumber: string | null
  notes: string | null
  documents: AssetFile[]
}
interface OwnState {
  assetData: AssetFormType
  plants: Array<{ code: string; name: string }>
  sites: Site[]
  costCenters: Array<{ plant: string; costCenters: CostCenter[] }>
  groups: GroupsListElement[]
  modules: Module[]
  documents: AssetFileCreation[]
  isSubmitting: boolean
  isFetching: boolean
  createAssetFrom: 'new-asset' | 'frozen-asset'
  deactivatedAssets: DeactivatedAsset[]
  selectedDeactivatedAsset?: DeactivatedAsset
}

type Props = RouteComponentProps & WithTranslation

const assetResetData = {
  assetCode: '',
  activationDate: moment().format('YYYY-MM-DD'),
  positionCode: '', // Codice Impianto
  assetDescription: '', // Descrizione assrt
  assetNotes: '', // Note
  assetBrand: '', // Marcca
  assetModel: '', // Model
  assetSerialNumber: '', // Matricola
  groups: [], // gruppo cespiti
  modules: [], // moduli
  documents: [],
}

class CreateAssetPageView extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      assetData: {
        assetCode: '',
        activationDate: moment().format('YYYY-MM-DD'),
        positionCode: '', // Codice Impianto
        assetDescription: '', // Descrizione assrt
        assetNotes: '', // Note
        siteCode: '', // Sito
        plantCode: '', // Impianto
        costCenterCode: '', // Centro di costo
        assetBrand: '', // Marcca
        assetModel: '', // Model
        assetSerialNumber: '', // Matricola
        ownership: '',
        groups: [], // gruppo cespiti
        modules: [], // moduli
        documents: [],
      },
      documents: [],
      plants: [],
      costCenters: [],
      groups: [],
      sites: [],
      modules: [],
      isSubmitting: false,
      isFetching: false,
      createAssetFrom: 'new-asset',
      deactivatedAssets: [],
    }
    this.fetchData = this.fetchData.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleOnChangeAssetForm = this.handleOnChangeAssetForm.bind(this)
    this.handleInputSelectDeactivatedAssetChange = this.handleInputSelectDeactivatedAssetChange.bind(this)
    this.updateAssetData = this.updateAssetData.bind(this)
    this.getInputRule = this.getInputRule.bind(this)
  }

  public componentDidMount() {
    this.fetchData()
  }

  public render() {
    const { t } = this.props
    const {
      assetData,
      sites,
      plants,
      costCenters,
      groups,
      modules,
      isSubmitting,
      documents,
      isFetching,
      createAssetFrom,
    } = this.state
    return (
      <div className="inplant-asset-manager-fe">
        <form onSubmit={this.handleSubmit}>
          {/* Header Section*/}
          <PageHeader
            title={t('assetManager.navigation.createAsset')}
            rightButtons={
              <Button
                variant={'primary'}
                type="submit"
                disabled={isSubmitting}
                label={t('assetManager.actions.save')}
                isLoading={isSubmitting}
              />
            }
          />
          {/* End Header Section*/}
          {/* Content Section*/}
          <div className="content">
            {!isFetching ? (
              <Row>
                <Column md={8}>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="radio-create-from-new-asset"
                      value="new-asset"
                      checked={createAssetFrom === 'new-asset'}
                      onChange={this.handleOnChangeAssetForm}
                    />
                    <label className="form-check-label" htmlFor="radio-create-from-new-asset">
                      Crea da nuovo asset
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="radio-create-from-freezed-asset"
                      value="frozen-asset"
                      checked={createAssetFrom === 'frozen-asset'}
                      onChange={this.handleOnChangeAssetForm}
                    />
                    <label className="form-check-label" htmlFor="radio-create-from-freezed-asset">
                      Crea da asset congelato
                    </label>
                  </div>
                  {createAssetFrom === 'frozen-asset' && this.state.deactivatedAssets && (
                    <Row>
                      <Column md={6}>
                        <SearchableSelect
                          label={t('assetManager.forms.deactivatedAsset')}
                          labelClassName="font-weight-bold"
                          onChange={this.handleInputSelectDeactivatedAssetChange}
                          value={
                            (this.state.selectedDeactivatedAsset && this.state.selectedDeactivatedAsset.description) ||
                            ''
                          }
                          options={this.state.deactivatedAssets.map(deactivatedasset => {
                            return {
                              label: `${deactivatedasset.code} - ${deactivatedasset.description} ${
                                deactivatedasset.serialNumber ? `- Matricola: ${deactivatedasset.serialNumber}` : ''
                              }`,
                              value: deactivatedasset.id,
                            }
                          })}
                        />
                      </Column>
                    </Row>
                  )}
                  {(createAssetFrom === 'new-asset' || this.state.selectedDeactivatedAsset) && (
                    <>
                      <AssetForm
                        assetData={assetData}
                        groups={groups}
                        costCenters={costCenters}
                        documents={documents}
                        modules={modules}
                        plants={plants}
                        sites={sites}
                        updateAssetData={this.updateAssetData}
                        type="create"
                        getInputRule={this.getInputRule}
                      />
                      <Row horizontalAlignment={'end'}>
                        <Button
                          variant={'primary'}
                          type="submit"
                          disabled={isSubmitting}
                          label={t('assetManager.actions.save')}
                          isLoading={isSubmitting}
                        />
                      </Row>
                    </>
                  )}
                </Column>
              </Row>
            ) : (
              <Loader />
            )}
          </div>
          {/* End Content Section*/}
        </form>
      </div>
    )
  }

  private getInputRule(name: string, action: string) {
    if (name === 'positionCode' && action === 'adviseKey' && !this.state.assetData.temporaryPlantCode) {
      return false
    }

    // if (name === 'add-document-button' && action === 'hide' && this.state.createAssetFrom === 'frozen-asset') {
    //   return true
    // }

    return getAssetFormRules(name, action, 'create')
  }

  private handleOnChangeAssetForm(event: React.ChangeEvent<HTMLInputElement>) {
    if (this.state.selectedDeactivatedAsset && event.target.value === 'new-asset') {
      this.setState({
        assetData: {
          ...this.state.assetData,
          ...assetResetData,
        },
      })
    }
    this.setState({
      createAssetFrom: event.target.value as 'new-asset' | 'frozen-asset',
      selectedDeactivatedAsset: undefined,
    })
  }

  private handleInputSelectDeactivatedAssetChange(assetId: string | null) {
    const selectedDeactivatedAsset: DeactivatedAsset | undefined =
      this.state.deactivatedAssets.find(obj => obj.id === assetId) || undefined

    if (selectedDeactivatedAsset) {
      this.setState({
        selectedDeactivatedAsset,
        assetData: {
          ...this.state.assetData,
          assetCode: selectedDeactivatedAsset.code,
          assetBrand: selectedDeactivatedAsset.brand || '',
          assetDescription: selectedDeactivatedAsset.description,
          assetModel: selectedDeactivatedAsset.model || '',
          assetSerialNumber: selectedDeactivatedAsset.serialNumber || '',
          assetNotes: selectedDeactivatedAsset.notes || '',
          modules: [],
          positionCode: '',
          temporaryPlantCode: false,
          groups: [],
          documents: selectedDeactivatedAsset.documents,
        },
        documents: [],
      })
    }
  }

  private async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    this.setState({
      isSubmitting: true,
    })
    const documents: AssetFileUpload[] = []

    // Insert every document from assetData.document
    for (const file of this.state.assetData.documents) {
      const assetFile: AssetFileUpload = {
        id: file.id,
        fileId: file.fileId,
        name: file.name,
        isInHeadquarter: file.isInHeadquarter || false,
        description: file.description || '',
        type: file.type || '',
      }
      documents.push(assetFile)
    }

    // Upload every document in this.state.documents
    for (const file of this.state.documents) {
      const result: FetchUploadDocumentSuccess = await fetchUploadFIle(file.file!)
      const assetFile: AssetFileUpload = {
        fileId: result.id,
        name: result.name,
        isInHeadquarter: file.isInHeadquarter || false,
        description: file.description || '',
        type: file.type || '',
      }
      documents.push(assetFile)
    }

    const data = JSON.parse(JSON.stringify(this.state.assetData))
    Object.keys(data).forEach(k => {
      if (!data[k] && typeof data[k] === 'string') {
        data[k] = null
      }
    })
    if (this.state.createAssetFrom === 'frozen-asset' && this.state.selectedDeactivatedAsset) {
      data.frozenAssetId = this.state.selectedDeactivatedAsset.id
    }
    delete data.assetCode
    data.documents = documents

    fetchCreateAsset(data)
      .then((result: FetchSuccessCreateAsset) => {
        this.props.history.push(`/asset-manager/detail-asset/${result.id}`)
      })
      .catch((error: any) => {
        this.setState({ isSubmitting: false })
        console.log(error) //tslint:disable-line
      })
  }

  private async fetchData() {
    if (!this.state.isFetching) {
      this.setState({
        isFetching: true,
      })
      Promise.all([fetchSitesGroupsModules(), fetchDeactivatedAssetsList()])
        .then(([sitesData, deactivatedAssets]) => {
          const plantsArray: Array<{ code: string; name: string }> = sitesData.sites.reduce(
            (acc: Array<{ code: string; name: string }>, sites) => {
              acc = acc.concat(
                sites.plants.reduce((plants: Array<{ code: string; name: string }>, plant) => {
                  plants.push({
                    code: plant.code,
                    name: plant.name,
                  })
                  return plants
                }, [])
              )
              return acc
            },
            []
          )
          const plantCode = /* plantsArray[0].code */ ''
          const findedSite = sitesData.sites.find(site => site.plants.find(plant => plant.code === plantCode))
          const costCenters: Array<{ plant: string; costCenters: CostCenter[] }> = sitesData.sites.reduce(
            (acc: Array<{ plant: string; costCenters: CostCenter[] }>, site) => {
              return acc.concat(
                site.plants.reduce((acc2: Array<{ plant: string; costCenters: CostCenter[] }>, plant) => {
                  acc2.push({
                    plant: plant.code,
                    costCenters: plant.costCenters,
                  })
                  return acc2
                }, [])
              )
            },
            []
          )
          const costCentersForPlant = costCenters.find(plant => plant.plant === plantCode)
          this.setState({
            sites: sitesData.sites,
            groups: sitesData.groups,
            modules: sitesData.modules,
            plants: plantsArray,
            costCenters,
            assetData: {
              ...this.state.assetData,
              plantCode,
              siteCode: (findedSite && findedSite.code) || '',
              costCenterCode: (costCentersForPlant && costCentersForPlant.costCenters[0].code) || '',
            },
            deactivatedAssets,
          })
        })
        .catch(error => {
          console.log(error) //tslint:disable-line
        })
        .finally(() => {
          this.setState({ isFetching: false })
        })
    }
  }

  private updateAssetData(assetData: AssetFormType) {
    this.setState({
      assetData,
    })
  }
}

export default withRouter<any, any>(withTranslation()(CreateAssetPageView))
